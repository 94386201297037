import { Typography } from 'antd';

function Dashboard() {
  return (
    <>
      <Typography.Title level={2}>Dashboard</Typography.Title>
    </>
  );
}

export default Dashboard;
