import { Typography } from 'antd';

function Seguimiento() {
  return (
    <>
      <Typography.Title level={2}>Seguimiento</Typography.Title>
    </>
  );
}

export default Seguimiento;
