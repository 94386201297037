import { Typography } from 'antd';

function Modelos() {
  return (
    <>
      <Typography.Title level={2}>Modelos</Typography.Title>
    </>
  );
}

export default Modelos;
