import { Typography } from 'antd';

function Perfil() {
  return (
    <>
      <Typography.Title level={2}>Perfil</Typography.Title>
    </>
  );
}

export default Perfil;
